import React from 'react';


class Results extends React.Component{

    render(){

        return(
            <div className={"content page"}>
                <section>
                    <h1>GAME OVER, MAN</h1>
                    <h3>Your Score</h3>
                    <p>{this.props.points}/{this.props.points_max}</p>
                    <h3>Longest Streak</h3>
                    <p>{this.props.streak_longest}</p>
                    <button className="btn btn-primary" onClick={this.props.callback}>Keep on playing!</button>
                </section>
            </div>
        )
    }
}


export default Results;