import React from 'react';
import axios from 'axios';

import {shuffleArray} from './Common.js';

import Intro from './Intro';
import Ad from './Ad';
import Results from './Results';
import FeedItem from './FeedItem';

import '../assets/css/style.css';


class App extends React.Component{

    _build = true;//((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? false : true);

    init_state = {
        counter: 0,
        counter_total: 0,
        items: [],
        items_per_page: 20,
        items_total: 0,
        allItems: {},
        images: null,
        currentItem: null,
        selectedItem: null,
        selectedItems: [],
        points: 0,
        streak: 0,
        streak_longest: 0,
        gameover: false,
        //quizID: (window.quizID || 634),
        title: '',
        page: 1,//(window.quizStartPage || 1),
        pages_total: 20,
        page_load: this._build,
        currentBatchKey: 1971,
        show_ad: false,
        show_results: false,
        initLoadProgress: null
    };

    state = this.init_state;


    componentDidMount(){

            if(!this.state.images) this.loadFeed(1);

    }

    closeAd =()=>{

        this.setState({
            show_ad: false
        });

    }

    closeIntro =()=>{

        this.setState({
            page_load: false
        });

    }

    updateBatch =(value)=>{
        const newItems = this.state.allItems[value];

        this.setState({
            pages_total: newItems.length,
            items: newItems,
            currentItem:  newItems[0],
            currentImage: this.state.images[newItems[0].imdb_id][0],
            title: this.renderTitle(value),
            currentBatchKey: parseInt(value) //will break without parseInt
        });

        this.closeIntro();
    }

    urlArr = [];
    parseImageDataForDownload =(data)=> {

            for (const key in data) {
                data[key].forEach((x, i) => {
                    this.urlArr.push(x.url);
                });
            }

        console.log(this.urlArr);//copy object from inspector
        this.urlArr = null;
    }


    loadFeed = async (_p) => {
        console.log('loadFeed');

        let opts = {
            method: "get",
            onDownloadProgress: (progressEvent) => {
                //console.log(progressEvent, progressEvent.srcElement.getResponseHeader('content-length'));
                this.setState({
                    initLoadProgress:  progressEvent.loaded
                });

            }
        }

        const requestOne = axios.get('https://quiztin.thesamp.com/data/moviesData.more.json', opts);
        const requestTwo = axios.get('https://quiztin.thesamp.com/data/imagesData.json',  opts);

        await axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            //console.log(responses[0], responses[1], parseInt(this.state.currentBatchKey));
            const newItems = shuffleArray(responses[0].data[this.state.currentBatchKey]);
            //this.parseImageDataForDownload(responses[1].data);//when you wanna export image array for download HOX memory hog!

            this.setState({
                allItems: responses[0].data,
                items: newItems,
                images: responses[1].data,
                items_total: Object.keys(newItems).length,
                pages_total: Object.keys(responses[0].data).length,
                currentItem:  newItems[this.state.counter],
                currentImage: shuffleArray(responses[1].data[newItems[this.state.counter].imdb_id])[0],
                title: this.renderTitle(this.state.currentBatchKey)
            });

            document.title = this.renderTitle(this.state.currentBatchKey) + ' - Quiztin Timerino';

                if(!this._build) this.closeIntro();

        })).catch(errors => {
            // react on errors.
            console.log(errors);
        });
    };

    renderTitle =(value)=>{
        return (typeof parseInt(value) === 'number' ? 'Year ' : 'List ')  + value
    }


    gotoNextGame =()=>{
        const newKey = this.state.currentBatchKey+1;
        const newItems = (this.state.allItems[newKey] || null);

            if(!newItems) window.location.reload();

        this.setState((state, props) => ({...state,
            counter: 0,
            counter_total: 0,
            //images: this.state.images,
            items_total: Object.keys(newItems).length,
            selectedItem: null,
            selectedItems: [],
            points: 0,
            streak: 0,
            streak_longest: 0,
            gameover: false,
            page: 1,
            pages_total: newItems.length,
            items: newItems,
            currentItem:  newItems[0],
            currentImage: this.state.images[newItems[0].imdb_id][0],
            title: this.renderTitle(newKey),
            currentBatchKey: newKey,
            show_ad: true,
            show_results: false,
        }));

        document.title = this.renderTitle(newKey) + ' - Quiztin Timerino';

    }


    selectItem =(id)=> {

        let nPoints = this.state.points, nStreak = 0, is_game_over = false;// is_batch_over = false,

            if(this.state.currentItem.id === id){
                nPoints = this.state.points + 1;
                nStreak = this.state.streak + 1;
            }

        let sItems = this.state.selectedItems;
        sItems.push(id);

        let nCounter = this.state.counter + 1;
        const nCounter_total = this.state.counter_total + 1;
        let nPage = this.state.page;

            if(nCounter >= this.state.items.length) nCounter = this.state.items.length-1;

            if(nCounter >= this.state.items_per_page){
                nCounter = 0;
                nPage = this.state.page + 1;
                //is_batch_over = true;
            }

                if(nCounter_total >= this.state.items_total) is_game_over = true;

            this.setState({
                selectedItem: id,
                selectedItems: sItems,
                counter: nCounter,
                counter_total: nCounter_total,
                currentItem: this.state.items[nCounter],
                currentImage: (typeof this.state.items[nCounter] !== 'undefined' ? shuffleArray(this.state.images[this.state.items[nCounter].imdb_id])[0] : false),
                points: nPoints,
                streak: nStreak,
                page: nPage,
                streak_longest: (nStreak > this.state.streak_longest ? nStreak : this.state.streak_longest),
                show_ad: (nCounter_total === Math.ceil(this.state.items_total/2)),
                gameover: is_game_over
            });

                if(is_game_over){
                    setTimeout(()=>{
                        this.setState({
                            show_results: true
                        })
                    }, 2000);
                }

        window.scrollTo(0, 0);

    }


    render(){

            if(!this.state.currentItem) return <div className="esd-loading"><p>Loading... {this.state.initLoadProgress} bits</p></div>

            if(this.state.page_load) return <Intro title={this.state.title} startGame={this.closeIntro} updateBatch={this.updateBatch} pages_total={this.state.pages_total} currentBatchKey={this.state.currentBatchKey} />

        let tmpItems = this.state.items.slice();
        delete tmpItems[this.state.counter];

        tmpItems = shuffleArray(tmpItems).splice(0, 4);
        tmpItems.unshift(this.state.currentItem);
        let batch = shuffleArray(tmpItems);
        tmpItems = null;

        let content = '';
        
            if(this.state.show_results) content = <Results callback={this.gotoNextGame} streak_longest={this.state.streak_longest} points={this.state.points} points_max={this.state.items_total} />
            else content = <FeedItem title={this.state.title} page={this.state.page} img={this.state.currentImage} selectItem={this.selectItem} item={this.state.currentItem} batch={batch} items={this.state.items} items_total={this.state.items_total} counter={this.state.counter} streak={this.state.streak} streak_longest={this.state.streak_longest} points={this.state.points} is_game_over={this.state.gameover}/>

        return(
            <>
                <section>
                    {content}
                    <div className="divider divider-sm"></div>
                    <footer><p>App by <a href="https://thesamp.com" target="_blank" rel="noopener noreferrer">EL SAMPO Digital</a></p><p>Data by <a href="https://themoviedb.org" target="_blank" rel="noopener noreferrer">themoviedb.org</a></p></footer>
                </section>
                {(this.state.show_ad && <Ad close={this.closeAd} />)}
            </>
        )
    }
}

//<p>counter: {this.state.counter}/{this.state.items_per_page}</p>
//<p>page: {this.state.page}/{this.state.pages_total}</p>

export default App;