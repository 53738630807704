export const USERVARS = {
    ELEMENT: '#quiztin'//,
    //ACCESSTOKEN: (window.sampinstaVARS ? window.sampinstaVARS.ACCESSTOKEN : null),
    //FETCHITEMS: (window.sampinstaVARS ? (window.sampinstaVARS.limit || 12) : 12)
};


export const shuffleArray =(array)=> {
    return array.map((a) => ({sort: Math.random(), value: a}))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
}