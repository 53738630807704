import React from 'react';

import {shuffleArray} from './Common.js';


class FeedItem extends React.Component{


    state = {
        show_hint: false,
        show_mask: true,
        correct: null,
        id: null,
        zoomed: false
    }

    _img_loaded = true;

    selectItem =(id)=> {

       this.setState({
            show_mask: false,
            show_hint: false,
            correct: (this.props.item.id === id)
        });

        this.props.selectItem(id);
        this._img_loaded = false;

            if(!this.props.is_game_over){
                const _t = this;
                setTimeout(function(){
                    _t.setState({
                        show_mask: true,
                        correct: null
                    });
                }, 1000);
            }
    }


    showHint =()=>{
        this.setState({
            show_hint: true
        });
    }


    hideHint =()=>{
        this.setState({
            show_hint: false
        });
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    componentDidMount(){
       //console.log(this.props);
        this._img_loaded = true;
    }


    shouldComponentUpdate() {

            if(this.props.is_game_over) return false;
            else return true;
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log(prevState.id, this.state.id);

            if (prevProps.item.id !== this.state.id) {
                    this._img_loaded = true;
            }
    }

    imgZoom =()=> {
        if(this.state.zoomed) {
            this.setState({
                zoomed: false
            });
        }else{
            this.setState({
                zoomed: true
            });
        }
    }

    render(){

            if(!this.props.item) return 'Loading...';

        //creating options
        const renderedList = this.props.batch.map((item, index) => {
            return <button className="btn btn-primary" key={index.toString()} onClick={()=>this.selectItem(item.id)}>{item.title}</button>
        });

        let title_scrambled = '';

        this.props.item.title.split(' ').forEach(function(el){
            title_scrambled += shuffleArray(el.split('')).join('').toLowerCase() + ' ';
        });

        const current_counter = (((this.props.page - 1) * 20) + this.props.counter + 1);

        let progressStyle = {
            width: (current_counter/this.props.items_total) * 100 + '%'
        };

        const box_overlay = <div id="hintOverlay" className="box ">
                                <p>Movie title scrambled</p>
                                <h3>{title_scrambled}</h3>
                                <button className="btn btn-3rd btn-square" onClick={this.hideHint}><i className="fas fa-times"></i></button>
                            </div>   

        let imgElement = <div className={"hint"}><h2>Movie is missing an image</h2><h4>Here's a hint</h4><p>{(this.props.item.overview)}</p></div>;

            if(this.props.img) imgElement = <img onClick={this.imgZoom} src={'https://storage.googleapis.com/quiztin-media/pictures/' + this.props.img.url.split('/').slice(-1)[0]} className={"img-fluid img-figure" + (this.props.img.width < this.props.img.height ? " img-vertical" : "")} alt="Quiztin - from which movie is this?" />

        return(
            <div className={"content game" + (typeof this.state.correct === 'boolean' ? ' result ' + (this.state.correct ? 'correct' : 'wrong') : '')}>
                <figure className={"figure" + (this.state.zoomed ? " zoomed" : "")}>
                    {(this._img_loaded ?  imgElement : <div className="esd-loading"></div>)}
                    <div id="resultScreen">
                        {(this.state.id === this.props.item.id ? {imgElement} : '')}
                        <div className="correct"><h2>Streak +1</h2><p>Score +1</p></div> 
                        <div className="wrong"><h2>Streak died</h2><p>No points</p></div> 
                    </div>
                    <button className="btn btn-3rd btn-square" onClick={this.imgZoom}>{(this.state.zoomed ? <i className="fas fa-compress"></i> : <i className="fas fa-expand"></i>)}</button>
                    <button className="btn btn-3rd btn-square" onClick={this.showHint}><i className="fas fa-mortar-pestle"></i></button>
                    {(this.state.show_hint ? box_overlay : '')}
                    <p className="title">{this.props.title}</p>
                    <div id="progress"><span style={progressStyle}></span><span className="progress-text">{current_counter}/{this.props.items_total}</span></div>
                </figure>
                <header className="row no-gutters">
                    <div className="col"><span>Streak</span><span>{this.props.streak}</span></div> 
                    <div className="col"><span>Max Streak</span><span>{this.props.streak_longest}</span></div>
                    <div className="col"><span>Score</span><span>{this.props.points}/{this.props.items_total}</span></div>
                </header>
                <section>
                    {renderedList}
                </section>
                <div className="divider divider-sm"></div>
                <button className="btn btn-secondary" onClick={()=> window.location.reload()}><i className="fas fa-redo"></i>Restart game</button>
            </div>
        )
    }
}


export default FeedItem;