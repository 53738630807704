import React from 'react';


class Intro extends React.Component{

    state = {
        selectedBatchKey: this.props.currentBatchKey
    }

    updateBatchKey =(evt)=>{

        const newval = evt.target.value;
        this.setState({
            selectedBatchKey: newval
        },()=> this.props.updateBatch(newval));

    }


    render(){

        let options = [];

            for(let i = this.props.currentBatchKey; i < (this.props.currentBatchKey + this.props.pages_total); i++){
                options.push(<option key={i} value={parseInt(i)}>{i}</option>);
            }

        return(
            <div className="content page page-intro">
                <section>
                    <h1>Quiztin Timerino</h1>
                    <p>Can you guess the movie from a picture?</p>
                    <span className="divider"></span>
                    <h4>Select a batch</h4>
                    <div className={"select-set"}>
                        <select onChange={this.updateBatchKey} name={"currentBatchKey"}>{options}</select>
                    </div>
                    <p>
                        <span className="divider"></span>
                    </p>
                    <button className="btn btn-secondary" onClick={this.props.startGame}>Start</button>
                </section>
            </div>
        )
    }
}


export default Intro;