import React from 'react';

class Ad extends React.Component{

    state = {
        opening: false,
        closing: false
    }

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});

        setTimeout(()=> {
            this.setState({
                opening: true
            })
        }, 500);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    closePage =()=> {

        this.setState({
            opening: false,
            closing: true
        });

        setTimeout(this.props.close, 500);
    }


    render(){

        return(
                <div className={"content page page-animate page-animate-" + (this.state.opening ? "in" : "out")}>
                    <section>
                        <h1>Hi, I am Bob Businezz</h1>
                        <p>Here will be an ad when it's activated.</p>
                        <ins className="adsbygoogle"
                             data-ad-client="ca-pub-4131417218036823"
                             data-ad-slot="1840473390"
                             data-ad-format="auto"
                             data-full-width-responsive="true">
                        </ins>
                        <button className="btn btn-secondary" onClick={this.closePage}>Close ad</button>
                    </section>
                </div>
        )
    }
}


export default Ad;